import { Controller } from '@suttyweb/stimulus'

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String,
    clipboard: String
  }

  static targets = [ 'fallback' ]

  async share (event = undefined) {
    event?.preventDefault()
    event?.stopPropagation()

    const title = this.titleValue
    const text = this.textValue
    const url = this.urlValue
    const data = { title, text, url }

    if ('share' in navigator) {
      if (navigator.canShare(data)) {
        navigator.share(data)
      } else {
        console.error('No se puede compartir', data)
      }
    } else if ('clipboard' in navigator) {
      if (!this.hasFallbackTarget) return

      navigator.clipboard.writeText(this.fallbackTarget.value)
      this.copied()
    } else {
      if (!this.hasFallbackTarget) return

      this.fallbackTarget.select()
      document.execCommand('copy')
      this.copied()
    }
  }

  copied () {
    const content = this.clipboardValue.trim()

    if (content.length === 0) return

    window.dispatchEvent(new CustomEvent('toast', { detail: { content }}))
  }
}
